<template>
  <div id="app">
    <!-- 顶部公共区域 -->
    <div class="top_container">
      <!-- logo -->
      <img
        class="logoImage"
        src="./assets/indexView/logoTop.png"
        alt=""
        srcset=""
      />
      <div class="menu_container">
        <!-- 电脑端导航菜单 -->
        <el-menu class="el-menu-demo" mode="horizontal" router>
          <el-menu-item index="/">首页</el-menu-item>
          <el-submenu index="2">
            <template slot="title">租车车型</template>
            <el-menu-item index="/carRentalModel/sedan">轿车</el-menu-item>
            <el-menu-item index="/carRentalModel/business">商务车</el-menu-item>
            <el-menu-item index="/carRentalModel/minibus">中巴车</el-menu-item>
            <el-menu-item index="/carRentalModel/bus">大巴车</el-menu-item>
          </el-submenu>
          <el-menu-item index="/carRentalQuoteView">租车报价</el-menu-item>
          <el-menu-item index="/carRentalProcessView">租车流程</el-menu-item>
          <el-menu-item index="/carRentalNewsView">租车动态</el-menu-item>
          <!-- <el-menu-item index="/carRentalCaseView">租车案例</el-menu-item> -->
          <el-menu-item index="/about" v-if="!basicInformation.isHiddenMode"
            >关于我们</el-menu-item
          >
        </el-menu>
      </div>
      <!-- 联系方式 -->
      <div
        :class="{ telBox: true, telBox_active: basicInformation.isHiddenMode }"
      >
        <img
          class="telBox_img"
          src="./assets/indexView/tel.png"
          alt=""
          srcset=""
        />
        <div class="telBox_tel">
          <div>{{ basicInformation.landlineNumber }}</div>
          <div>{{ basicInformation.cellPhone }}</div>
        </div>
      </div>
      <!-- 移动端汉堡菜单图标 -->
      <div class="hamburger-menu" @click="toggleMobileMenu">
        <i class="el-icon-s-fold"></i>
      </div>
    </div>
    <!-- 移动端菜单覆盖层 -->
    <div
      class="mobile-menu-overlay"
      v-if="isMobileMenuOpen"
      @click="toggleMobileMenu"
    >
      <div class="mobile-menu" @click.stop>
        <!-- 在这里添加移动端菜单项 -->
        <el-menu mode="vertical" router>
          <el-menu-item index="/" @click="toggleMobileMenu">首页</el-menu-item>
          <el-submenu index="2">
            <template slot="title">租车车型</template>
            <el-menu-item
              index="/carRentalModel/sedan"
              @click="toggleMobileMenu"
              >轿车</el-menu-item
            >
            <el-menu-item
              index="/carRentalModel/business"
              @click="toggleMobileMenu"
              >商务车</el-menu-item
            >
            <el-menu-item
              index="/carRentalModel/minibus"
              @click="toggleMobileMenu"
              >中巴车</el-menu-item
            >
            <el-menu-item index="/carRentalModel/bus" @click="toggleMobileMenu"
              >大巴车</el-menu-item
            >
          </el-submenu>
          <el-menu-item index="/carRentalQuoteView" @click="toggleMobileMenu"
            >租车报价</el-menu-item
          >
          <el-menu-item index="/carRentalProcessView" @click="toggleMobileMenu"
            >租车流程</el-menu-item
          >
          <el-menu-item index="/carRentalNewsView" @click="toggleMobileMenu"
            >租车动态</el-menu-item
          >
          <!-- <el-menu-item index="/carRentalCaseView" @click="toggleMobileMenu"
            >租车案例</el-menu-item
          > -->
          <el-menu-item
            index="/about"
            @click="toggleMobileMenu"
            v-if="!basicInformation.isHiddenMode"
            >关于我们</el-menu-item
          >
        </el-menu>
      </div>
    </div>
    <router-view />
    <!-- 右侧区域 -->
    <div class="rightBox" v-if="!basicInformation.isHiddenMode">
      <div class="rightBox_tel">
        <div class="rightBox_tel_one">
          <img
            class="rightBox_tel_one_img"
            src="./assets/indexView/right_tel.png"
            alt=""
            srcset=""
          />
          <div>电话</div>
        </div>
        <div class="rightBox_tel_two">{{ basicInformation.cellPhone }}</div>
      </div>
      <div class="rightBox_wx">
        <div class="rightBox_wx_one">
          <img
            class="rightBox_wx_one_img"
            src="./assets/indexView/right_wx.png"
            alt=""
            srcset=""
          />
          <div>微信</div>
        </div>
        <div class="rightBox_wx_two">
          <img
            class="rightBox_wx_two_img"
            :src="basicInformation.wxImage"
            alt=""
            srcset=""
          />
        </div>
      </div>
      <div class="rightBox_top" @click="scrollToTop">
        <div class="rightBox_top_one">
          <img
            class="rightBox_top_one_img"
            src="./assets/indexView/right_top.png"
            alt=""
            srcset=""
          />
          <div>返回顶部</div>
        </div>
      </div>
    </div>
    <!-- 底部公共区域 -->
    <div class="footer">
      <div class="footer_flr">
        <div class="footer_services">
          <div
            class="footer_services_title"
            v-if="!basicInformation.isHiddenMode"
          >
            提供服务
          </div>
          <ul>
            <li
              v-for="(item, index) in basicInformation.serveArray"
              :key="index"
            >
              {{ item }}
            </li>
          </ul>
        </div>
        <div class="footer_contact">
          <ul>
            <li>电话：{{ basicInformation.landlineNumber }}</li>
            <li>
              网址：<a :href="basicInformation.website">{{
                basicInformation.website
              }}</a>
            </li>
            <li>地址：哈尔滨市</li>
          </ul>
          <div class="qrcode">
            <img :src="basicInformation.wxImage" alt="微信客服" />
            <p>微信客服</p>
          </div>
        </div>
      </div>
      <div class="footer_bottom">
        <p>
          版权所有© {{ basicInformation.companyName }} Powered by Master Sun
          <a href="https://beian.miit.gov.cn/" target="_blank"
            >黑ICP备2024028981号</a
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      basicInformation: {},
      isMobileMenuOpen: false,
      isMobile: false,
    };
  },
  created() {
    this.getBasicInformation();
  },
  mounted() {
    this.checkScreenSize();
    window.addEventListener("resize", this.checkScreenSize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkScreenSize);
  },
  methods: {
    /**获取基本信息 */
    async getBasicInformation() {
      const db = this.$app.database();
      let res = await db.collection("MasterSun_basicInformation").get();
      this.basicInformation = res.data[0];
    },
    /**返回顶部 */
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    toggleMobileMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
    },
    checkScreenSize() {
      this.isMobile = window.innerWidth <= 768;
    },
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
} */
/* 顶部公共区域 */
.top_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.logoImage {
  width: 13.5rem;
  height: 6.145rem;
}

.el-menu-demo {
  display: inline-block;
  border-bottom-color: #ffffff !important;
}

.el-menu-demo .el-menu-item {
  border-bottom-color: #ffffff !important;
  font-size: 1rem;
}

.telBox {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.telBox_active {
  opacity: 0;
}

.telBox_img {
  width: 2.5rem;
  height: 2.5rem;
}

.telBox_tel {
  margin-left: 0.625rem;
  font-size: 1.125rem;
  font-weight: bold;
}

.hamburger-menu {
  display: none;
  font-size: 104px;
  cursor: pointer;
}

.mobile-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
}

.mobile-menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #005bac;
  padding: 20px;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .menu_container {
    display: none;
  }

  .hamburger-menu {
    display: block;
  }

  .top_container {
    justify-content: space-between;
  }

  .telBox_tel {
    font-size: 14px; /* 缩小字体大小以适应移动端 */
  }
}
/* 右侧区域 */
.rightBox {
  position: fixed;
  right: 0.625rem;
  top: 14.375rem;
  background-color: #fff;
  z-index: 9998;
  border: 0.0625rem solid #ddd;
  border-radius: 0.3125rem;
}
.rightBox_tel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0.3125rem;
  width: 5rem;
  height: 5rem;
  border-bottom: 0.0625rem solid #ddd;
}
.rightBox_tel_one {
  text-align: center;
}
.rightBox_tel_one_img {
  width: 2.5rem;
  height: 2.5rem;
}
.rightBox_tel_two {
  position: absolute;
  top: 0;
  left: -12.5rem;
  width: 12.5rem;
  height: 6.25rem;
  line-height: 6.25rem;
  color: #fff;
  font-size: 1.5625rem;
  text-align: center;
  background-color: #024b9a;
  visibility: hidden; /* 默认隐藏 */
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 9999;
}
.rightBox_tel:hover .rightBox_tel_two {
  visibility: visible; /* 鼠标悬停时显示 */
  opacity: 1;
}

.rightBox_tel:hover {
  color: #fff;
  background-color: #1e4a95; /* 鼠标悬停时改变背景色 */
}

.rightBox_wx {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0.3125rem;
  width: 5rem;
  height: 5rem;
  border-bottom: 0.0625rem solid #ddd;
}
.rightBox_wx_one {
  text-align: center;
}
.rightBox_wx_one_img {
  width: 2.5rem;
  height: 2.5rem;
}
.rightBox_wx_two {
  position: absolute;
  top: -1.875rem;
  left: -13.125rem;
  width: 11.875rem;
  height: 8.75rem;
  line-height: 6.25rem;
  padding: 0.625rem;
  color: #fff;
  font-size: 1.5625rem;
  text-align: center;
  background-color: #024b9a;
  visibility: hidden; /* 默认隐藏 */
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 9999;
}
.rightBox_wx_two_img {
  width: 8.75rem;
  height: 8.75rem;
}
.rightBox_wx:hover .rightBox_wx_two {
  visibility: visible; /* 鼠标悬停时显示 */
  opacity: 1;
}

.rightBox_wx:hover {
  color: #fff;
  background-color: #1e4a95; /* 鼠标悬停时改变背景色 */
}
.rightBox_top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0.3125rem;
  width: 5rem;
  height: 5rem;
  border-bottom: 0.0625rem solid #ddd;
}
.rightBox_top_one {
  text-align: center;
}
.rightBox_top_one_img {
  width: 1.875rem;
  height: 1.875rem;
}
.rightBox_top:hover .rightBox_top_one_img {
  /* 显示微信二维码 */
  content: url("./assets/indexView/right_top_active.png");
}

.rightBox_top:hover {
  color: #fff;
  background-color: #1e4a95; /* 鼠标悬停时改变背景色 */
}

/* 底部公共区域 */
.footer {
  color: #333;
  background-color: #f5f5f5;
}
.footer_flr {
  /* margin: 1.25rem;
  padding: 1.25rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; */
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
}

.footer_services {
  /* display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.25rem;
  padding-bottom: 1.25rem; */
  flex: 1 1 100%;
  margin-bottom: 20px;
}
.footer_services_title {
  /* flex-basis: 100%;
  font-size: 1.625rem;
  font-weight: bold;
  margin-bottom: 0.625rem; */
  font-size: 19.2px;
  font-weight: bold;
  margin-bottom: 10px;
}

.footer_services ul {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;
  margin: 0;
}

.footer_services li {
  /* cursor: pointer;
  flex: 1 1 25%;
  font-size: 0.875rem;
  line-height: 2; */
  flex: 1 1 25%;
  font-size: 14px;
  line-height: 2;
  white-space: nowrap;
}
.footer_services li:hover {
  font-size: 1rem;
  color: #003366;
}

.footer_contact {
  /* display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 6.25rem; */
  flex: 1 1 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer_contact ul {
  /* padding: 0;
  list-style: none; */
  flex: 1 1 auto;
  padding: 0;
  list-style: none;
  margin: 0;
}

.footer_contact li {
  font-size: 14px;
  line-height: 2;
}

.footer_contact a {
  color: #003366;
  text-decoration: none;
}

.footer_contact a:hover {
  text-decoration: underline;
}

.qrcode {
  /* margin-left: 0.625rem;
  text-align: center; */
  flex: 0 0 auto;
  text-align: center;
  margin-top: 16px;
}

.qrcode img {
  width: 100px;
  height: 100px;
}

.qrcode p {
  margin-top: 5px;
  font-size: 12px;
  color: #333;
}

.footer_bottom {
  /* text-align: center;
  font-size: 0.9375rem;
  color: white;
  height: 1.875rem;
  line-height: 1.875rem;
  background-color: #003366; */
  text-align: center;
  font-size: 12px;
  color: white;
  padding: 8px;
  background-color: #003366;
}
.footer_bottom a {
  color: white;
  text-decoration: none;
}

.footer_bottom a:hover {
  text-decoration: underline;
}
@media (max-width: 768px) {
  .footer_flr {
    flex-wrap: nowrap; /* Prevent wrapping on larger screens */
  }

  .footer_services {
    flex: 2; /* Take up more space than contact info */
    margin-bottom: 0;
  }

  .footer_contact {
    flex: 1;
    margin-left: 2rem;
  }

  .qrcode {
    margin-top: 0; /* Remove top margin on larger screens */
  }
  .rightBox {
    opacity: 0;
  }
}
</style>
